<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>系统用户</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="2" class="add-btn-container">
          <el-button type="text" @click="toCreate">添加管理员</el-button>
        </el-col>
      </el-row>
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-input v-model="name" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getAdminList">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 主要内容 -->
    <div class="main">
      <el-table :data="adminList" border="" style="width: 100%">
        <el-table-column prop="id" label="ID" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" align="center"></el-table-column>
        <el-table-column prop="account" label="登入账号" align="center"></el-table-column>
        <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="lastTime" label="最后登入时间" align="center"></el-table-column>
        <el-table-column prop="lastIp" label="最后登入Ip" align="center"></el-table-column>
        <el-table-column prop="states" label="状态" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-show="scope.row.states == 1">启用</el-tag>
            <el-tag type="danger" v-show="scope.row.states == 0">禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="213" align="center">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="setStates(scope.row.id, 0, scope.row)" v-if="scope.row.states == 1">禁用</el-button>
            <el-button type="success" size="mini" @click="setStates(scope.row.id, 1, scope.row)" v-else>启用</el-button>
            <el-button size="mini" @click="toEdit(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="mini" @click="onDelete(scope.row.id)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background="" layout="prev, pager, next" :page-size="10" :current-page="pageIndex" :total="pageTotal" @current-change="onCurrentChange" />
    </div>
  </div>
</template>

<script>
import { getListRequest, setStatesRequest, deluserRequest } from '@/api/admin'
export default {
  name: 'AdminList',
  data() {
    return {
      name: '',
      adminList: [],
      userName: '',
      pageIndex: 1,
      pageTotal: 0,
    }
  },
  created() {
    this.getAdminList()
  },
  methods: {
    getAdminList() {
      getListRequest({
        userName: this.name,
        pageNum: this.pageIndex,
        pageSize: 10,
      }).then((res) => {
        let data = res.data
        this.adminList = data.data
        this.pageTotal = data.count
      })
    },
    setStates(id, states, row) {
      setStatesRequest({
        Id: id,
        States: states,
      }).then((res) => {
        row.states = states
      })
    },
    toEdit(id) {
      this.$router.push({
        path: '/admin/edit',
        query: {
          id: id,
        },
      })
    },
    toCreate() {
      this.$router.push({
        path: '/admin/edit',
        query: {
          id: 0,
        },
      })
    },
    onDelete(id) {
      this.$confirm('确认删除该用户吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 调用删除接口
          return deluserRequest({ id })
        })
        .then(() => {
          this.$message({
            type: 'success',
            message: `删除成功！`,
          })
          this.getAdminList()
        })
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getAdminList()
    },
  },
}
</script>

<style lang="less" scoped>
</style>
